const edjsHTML = require('editorjs-html')
export default {
  data() {
    return {
      edjsParser: null
    } 
  },
  methods: {
    createOptions(arr) {
      const options = [
        {
          label: 'All',
          code: 'all',
          id: 'all',
        },
      ]
      arr.forEach((element) => {
        options.push({
          label: element.attributes.title,
          code: this.slugify(element.attributes.title),
          id: parseInt(element.id),
        })
      })
      return options
    },
    renderHTML(json) {
      const parsedJson = JSON.parse(json)
      let html = ''
      if (parsedJson) {
        parsedJson.blocks.forEach((element) => {
          html += this.renderBlock(element)
        })
      }
      return html
    },
    renderBlock(block) {
      if(!this.edjsParser) this.edjsParser = edjsHTML({ quote: this.customQuoteParser, paragraph:this.customParagraphParser  })
      return this.edjsParser.parseBlock(block)
    },
    customParagraphParser ({ data }) {
      const alignType = ["left", "right", "center", "justify"]
      const paragraphAlign = data.alignment || data.align;
      const parsedText = this.parseShortcodes(data.text)
      if (typeof paragraphAlign !== 'undefined' && alignType.includes(paragraphAlign)) {
        return `<p style="text-align:${paragraphAlign};">${parsedText}</p>`;
      } else {
        return `<p>${parsedText}</p>`
      }
    },
  
    customQuoteParser({ data }) {
      return `<blockquote><p>${data.text}</p><cite>- ${data.caption}</cite></blockquote>`;
    },
    customNewsletterParser(attributes) {
      return `<div class="newsletter"><form target="_blank" action="https://distillerycopenhagen.activehosted.com/f/${attributes.id}"><input type="text" placeholder="Email address" name="email" required><input type="submit" value="Submit"></form></div>`;
    },
    customBookingParser(attributes) {
      return `<div class="booking">
      <div class="holdbar-booking-widget" data-company-id="b2740616851b371ab3444a5b1dac78b4" data-experience-id="${attributes.id}" data-language="en"></div>
      </div>`;
    },
    customLinkParser(attributes) {
      const page = this.$store.state.pages.find(page => page.id === attributes.id)
      return `<div class="link"><a href="/${page.attributes.slug}" target="_self"><Btn>${attributes.cta}</Btn></a></div>`;
    },
    parseShortcodes(str) {
      let result = str
      const attributes = {};
      if (str.match(/[\w-]+=".*?"/g)) {
        const type = str.substring(1, str.indexOf(' '))
        str.match(/[\w-]+=".*?"/g).forEach(function (attribute) {
          
          attribute = attribute.match(/([\w-]+)="(.*?)"/);
          attributes.type = type
          attributes[attribute[1]] = attribute[2];
        })
      }
      switch (attributes.type) {
        case 'newsletter':
          result = this.customNewsletterParser(attributes)
          break;
        case 'booking':
          result = this.customBookingParser(attributes)
          break;
          case 'link':
            result = this.customLinkParser(attributes)
            break;
      }
      return result
    },
    slugify(str) {
      return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
    },
    getMenuSlug(item) {
      if (item.page_relation.data) {
        return '/' + item.page_relation.data.attributes.slug + (item.page_query || '')
      } else if (item.product_relation.data) {
        return '/spirits/' + item.product_relation.data.attributes.slug
      } else if (item.recipe_relation.data) {
        return '/cocktails/' + item.recipe_relation.data.attributes.slug
      } else if (item.event_relation.data) {
        return '/venue/' + item.event_relation.data.attributes.slug
      } else {
        return item.url
      }
    },
    getMenuImage(item) {
      let image = false
      if (item.page_media.data) {
        image = item.page_media.data
      } else if (item.page_relation.data) {
        const page = this.$store.state.pages.find(
          (page) => parseInt(page.id) === parseInt(item.page_relation.data.id)
        )
        if (
          page &&
          page.attributes.media.data &&
          page.attributes.media.data.length > 0
        ) {
          image = page.attributes.media.data[0]
        }
      } else if (item.product_relation.data) {
        const product = this.$store.state.products.find(
          (product) =>
            parseInt(product.id) === parseInt(item.product_relation.data.id)
        )
        if (
          product &&
          product.attributes.packshot.data &&
          product.attributes.packshot.data.length > 0
        ) {
          image = product.attributes.packshot.data[0]
        }
      }
      else if (item.recipe_relation.data) {
        const recipe = this.$store.state.recipes.find(
          (recipe) =>
            parseInt(recipe.id) === parseInt(item.recipe_relation.data.id)
        )
        if (
          recipe &&
          recipe.attributes.media.data &&
          recipe.attributes.media.data.length > 0
        ) {
          image = recipe.attributes.media.data[0]
        }
      }

      return image
    },
    sortOnBatch(arr) {
      return arr.sort(function (a, b) {
        const keyA = new Date(a.attributes.batch)
        const keyB = new Date(b.attributes.batch)
        // Compare the 2 dates
        if (keyA > keyB) return -1
        if (keyA < keyB) return 1
        return 0
      })
    },
    handleClicks($event, container) {
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = $event
      while (target && target.tagName !== 'A') target = target.parentNode
      // handle only links that occur inside the component and do not reference external resources
      if (target && target.href) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const {
          altKey,
          ctrlKey,
          metaKey,
          shiftKey,
          button,
          defaultPrevented
        } = $event
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return
        // don't handle when preventDefault called
        if (defaultPrevented) return
        // don't handle right clicks
        if (button !== undefined && button !== 0) return
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        if (target.href.includes('mailto:')) return
        // don't handle same page links/anchors
        const url = new URL(target.href)
        const to = url.pathname + url.search
        if (window.location.pathname !== to && $event.preventDefault && url.host === window.location.host) {
          $event.preventDefault()
          this.$router.push(to)
        }
      }
    },
  }
}
