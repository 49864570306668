import { render, staticRenderFns } from "./default.vue?vue&type=template&id=4117313e&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=4117313e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Announcement: require('/vercel/path0/components/Announcement.vue').default,MobileMenu: require('/vercel/path0/components/MobileMenu.vue').default,Header: require('/vercel/path0/components/Header.vue').default,Submenus: require('/vercel/path0/components/Submenus.vue').default})
